import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FitifyLogoTemporaryIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="176"
    height="48"
    viewBox="0 0 176 48"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 16.92h13.2v2.157H3.06v4.433h8.612v2.155H3.059v4.548c0 .403-.16.79-.447 1.075a1.531 1.531 0 0 1-2.16 0 1.517 1.517 0 0 1-.448-1.075L0 16.92ZM17.783 16.92h3.08v13.283a1.526 1.526 0 0 1-.451 1.084 1.54 1.54 0 0 1-1.09.448c-.408 0-.8-.161-1.088-.448a1.529 1.529 0 0 1-.45-1.084V16.921ZM31.131 19.096h-5.815V16.92h14.716v2.175h-5.828v11.107a1.525 1.525 0 0 1-.453 1.085 1.538 1.538 0 0 1-1.092.447 1.546 1.546 0 0 1-1.424-.945 1.526 1.526 0 0 1-.117-.587l.013-11.107ZM44.458 16.92h3.08v13.283c0 .407-.163.796-.452 1.084a1.544 1.544 0 0 1-2.177 0 1.529 1.529 0 0 1-.451-1.084V16.921ZM53.72 16.92h13.195v2.157H56.78v4.433h8.616v2.155H56.78v4.548c0 .403-.161.79-.448 1.075a1.531 1.531 0 0 1-2.16 0 1.518 1.518 0 0 1-.447-1.075L53.72 16.92ZM76.204 25.06l-6.43-8.14h3.414l5.015 6.34 4.774-6.34h3.029l-6.39 8.555v4.726a1.526 1.526 0 0 1-.451 1.084 1.541 1.541 0 0 1-1.09.448c-.408 0-.8-.161-1.088-.448a1.528 1.528 0 0 1-.451-1.084v-4.193a1.528 1.528 0 0 0-.332-.947ZM117.926 31.425h11.979v-2.176h-8.933V16.61h-3.046v14.815ZM138.739 31.632c5.161 0 7.834-2.217 7.834-6.693V16.61h-3.046v8.164c0 3.563-1.762 4.662-4.726 4.662-3.109 0-4.746-1.264-4.746-4.455v-8.37h-3.047v8.494c0 4.393 2.819 6.527 7.731 6.527ZM156.198 31.632c4.643 0 7.254-1.906 7.254-4.62 0-3.046-2.487-4.02-7.047-4.455-3.648-.394-4.539-.974-4.539-2.072 0-1.243 1.182-1.99 3.855-1.99 2.508 0 3.855.622 4.249 2.197h2.881c-.394-2.9-2.985-4.268-7.151-4.268-4.228 0-6.736 1.74-6.736 4.227 0 2.776 2.177 3.812 6.736 4.31 3.565.393 4.788.828 4.788 2.258 0 1.491-1.554 2.34-4.311 2.34-3.44 0-4.642-1.16-4.912-2.713h-2.984c.331 2.817 2.756 4.786 7.917 4.786ZM102.594 26.762h3.047v-.497h3.897c3.958 0 6.673-1.595 6.673-4.91v-.083c0-3.253-2.653-4.662-6.777-4.662h-6.84v10.152Zm3.047-2.569v-5.47h3.834c2.218 0 3.71.725 3.71 2.632v.082c0 1.824-1.368 2.756-3.71 2.756h-3.834Z"
    />
    <path
      fill="currentColor"
      d="M102.594 18.133V29.9a1.523 1.523 0 0 0 3.046 0V18.133a1.523 1.523 0 0 0-3.046 0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M151.992 2.072h-37.998C101.88 2.072 92.059 11.89 92.059 24c0 12.11 9.821 21.928 21.935 21.928h37.998c12.115 0 21.936-9.817 21.936-21.928 0-12.11-9.821-21.928-21.936-21.928ZM113.994 0c-13.259 0-24.007 10.745-24.007 24s10.748 24 24.007 24h37.998C165.252 48 176 37.255 176 24S165.252 0 151.992 0h-37.998Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
