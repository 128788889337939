import { Text } from 'fitify-ui'

import { ButtonLoader, ButtonStyles, resolveColor } from './Button.Styles'
import { ButtonProps } from './Button.Types'

const Button = ({
  children,
  href,
  loading,
  icon,
  variant = 'primary-blue',
  as = href ? 'a' : 'button',
  ...rest
}: ButtonProps) => {
  return (
    <ButtonStyles
      aria-label={'button'}
      {...{ $variant: variant, as }}
      {...rest}
      href={href}
    >
      {loading ? (
        <ButtonLoader $variant={variant} />
      ) : (
        <>
          <Text
            as={'span'}
            variant={'button'}
            fontWeight={500}
            fontFamily={'Graphik LCG Web'}
            color={resolveColor(variant)}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              alignContent: 'center',
              lineHeight: '100%',
            }}
          >
            {children}
          </Text>
          {icon}
        </>
      )}
    </ButtonStyles>
  )
}

export default Button
