import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const LogoMessengerIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.439 0 0.121002 4.973 0.121002 11.108C0.121002 14.598 1.842 17.712 4.534 19.748V24L8.588 21.751C9.669 22.054 10.814 22.216 12 22.216C18.561 22.216 23.879 17.243 23.879 11.108C23.879 4.973 18.561 0 12 0ZM13.251 14.896L10.179 11.704L4.262 14.976L10.749 8.092L13.821 11.284L19.738 8.012L13.251 14.896Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
