export * from './AndroidIcon'
export * from './AppleIcon'
export * from './AppleRoundedIcon'
export * from './FacebookColorIcon'
export * from './FitifyHcLogoPictogramIcon'
export * from './FitifyLogoIcon'
export * from './FitifyLogoTemporaryIcon'
export * from './GoogleColorIcon'
export * from './LogoFacebookIcon'
export * from './LogoInstagramIcon'
export * from './LogoMessengerIcon'
export * from './LogoSpotifyIcon'
export * from './LogoXIcon'
export * from './NotionIcon'
export * from './StravaRoundedIcon'
