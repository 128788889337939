import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AppleRoundedIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="6" ry="6" fill="#000" />
      <path
        fill="#fff"
        d="M18.236 13.992a2.409 2.409 0 0 1-1.556-2.113c-.066-.941.395-1.777 1.234-2.237l.697-.382-.498-.62c-.836-1.042-2.034-1.664-3.202-1.664-.81 0-1.372.212-1.824.382-.318.121-.57.216-.846.216-.314 0-.625-.111-.985-.239-.472-.168-1.007-.359-1.693-.359-1.327 0-2.665.792-3.492 2.066-1.234 1.899-.895 5.156.806 7.744C7.55 17.81 8.495 18.989 9.833 19h.026c1.096 0 1.336-.584 2.4-.59 1.16.054 1.307.595 2.392.587 1.307-.012 2.25-1.18 3-2.322.442-.672.613-1.023.958-1.786l.292-.646zM14.067 5.3c.433-.555.762-1.34.642-2.142-.708.049-1.534.499-2.018 1.085-.438.533-.8 1.322-.66 2.09.772.024 1.572-.437 2.036-1.032z"
      />
    </StyledIcon>
  )
}
