import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const FacebookColorIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
    fill="none"
  >
    <path
      d="M23 12C23 5.92486 18.0751 1 12 1C5.92486 1 1 5.92486 1 12C1 17.4903 5.02252 22.0412 10.2812 22.8664V15.1797H7.48828V12H10.2812V9.57656C10.2812 6.81969 11.9235 5.29688 14.4361 5.29688C15.6392 5.29688 16.8984 5.51172 16.8984 5.51172V8.21875H15.5114C14.145 8.21875 13.7188 9.06674 13.7188 9.9375V12H16.7695L16.2818 15.1797H13.7188V22.8664C18.9775 22.0412 23 17.4903 23 12Z"
      fill="#1877F2"
    />
    <path
      d="M16.2818 15.1797L16.7695 12H13.7188V9.9375C13.7188 9.0676 14.145 8.21875 15.5114 8.21875H16.8984V5.51172C16.8984 5.51172 15.6397 5.29688 14.4361 5.29688C11.9235 5.29688 10.2812 6.81969 10.2812 9.57656V12H7.48828V15.1797H10.2812V22.8664C11.4202 23.0445 12.5798 23.0445 13.7188 22.8664V15.1797H16.2818Z"
      fill="white"
    />
  </StyledIcon>
)
