import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AndroidIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.427 8.8501L20.338 5.5001C20.403 5.38598 20.4449 5.26017 20.4613 5.12986C20.4777 4.99956 20.4682 4.8673 20.4335 4.74064C20.3988 4.61398 20.3394 4.4954 20.2589 4.39167C20.1783 4.28794 20.0781 4.2011 19.964 4.1361C19.8499 4.07109 19.7241 4.0292 19.5938 4.01282C19.4635 3.99643 19.3312 4.00587 19.2045 4.0406C19.0779 4.07533 18.9593 4.13467 18.8556 4.21522C18.7519 4.29578 18.665 4.39598 18.6 4.5101L16.646 7.9341C15.1746 7.31758 13.5953 7.00007 12 7.00007C10.4047 7.00007 8.82536 7.31758 7.354 7.9341L5.4 4.5051C5.335 4.39098 5.24815 4.29078 5.14442 4.21022C5.0407 4.12967 4.92212 4.07033 4.79546 4.0356C4.53966 3.96546 4.26647 3.99981 4.036 4.1311C3.80553 4.26238 3.63665 4.47984 3.56651 4.73564C3.49637 4.99144 3.53072 5.26462 3.662 5.4951L5.573 8.8501C3.99872 9.83927 2.67583 11.1807 1.70869 12.7686C0.74154 14.3565 0.156596 16.1475 0 18.0001H24C23.8434 16.1475 23.2585 14.3565 22.2913 12.7686C21.3242 11.1807 20.0013 9.83927 18.427 8.8501ZM6 15.0001C5.80222 15.0001 5.60888 14.9414 5.44443 14.8316C5.27998 14.7217 5.15181 14.5655 5.07612 14.3828C5.00043 14.2001 4.98063 13.999 5.01921 13.805C5.0578 13.611 5.15304 13.4328 5.29289 13.293C5.43275 13.1531 5.61093 13.0579 5.80491 13.0193C5.99889 12.9807 6.19996 13.0005 6.38268 13.0762C6.56541 13.1519 6.72159 13.2801 6.83147 13.4445C6.94135 13.609 7 13.8023 7 14.0001C7 14.2653 6.89464 14.5197 6.70711 14.7072C6.51957 14.8947 6.26522 15.0001 6 15.0001ZM18 15.0001C17.8022 15.0001 17.6089 14.9414 17.4444 14.8316C17.28 14.7217 17.1518 14.5655 17.0761 14.3828C17.0004 14.2001 16.9806 13.999 17.0192 13.805C17.0578 13.611 17.153 13.4328 17.2929 13.293C17.4327 13.1531 17.6109 13.0579 17.8049 13.0193C17.9989 12.9807 18.2 13.0005 18.3827 13.0762C18.5654 13.1519 18.7216 13.2801 18.8315 13.4445C18.9414 13.609 19 13.8023 19 14.0001C19 14.2653 18.8946 14.5197 18.7071 14.7072C18.5196 14.8947 18.2652 15.0001 18 15.0001Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
