import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const GoogleColorIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
    fill="none"
  >
    <path
      d="M22.5647 12.2527C22.5647 11.5049 22.504 10.7532 22.3747 10.0176H11.9995V14.2533H17.9409C17.6943 15.6194 16.9022 16.8279 15.7422 17.5959V20.3442H19.2868C21.3683 18.4285 22.5647 15.5992 22.5647 12.2527Z"
      fill="#4285F4"
    />
    <path
      d="M11.9997 23.0002C14.9663 23.0002 17.4682 22.0262 19.291 20.3448L15.7464 17.5964C14.7602 18.2674 13.4871 18.6473 12.0037 18.6473C9.1341 18.6473 6.70097 16.7113 5.82795 14.1084H2.17017V16.9417C4.03745 20.656 7.84074 23.0002 11.9997 23.0002Z"
      fill="#34A853"
    />
    <path
      d="M5.82383 14.1082C5.36307 12.7421 5.36307 11.2629 5.82383 9.89674V7.06348H2.17009C0.609971 10.1716 0.609971 13.8334 2.17009 16.9415L5.82383 14.1082Z"
      fill="#FBBC04"
    />
    <path
      d="M11.9997 5.35371C13.5679 5.32945 15.0836 5.91955 16.2193 7.00274L19.3597 3.8623C17.3712 1.99501 14.7319 0.968407 11.9997 1.00074C7.84074 1.00074 4.03745 3.34496 2.17017 7.06337L5.82391 9.89663C6.69288 7.2897 9.13006 5.35371 11.9997 5.35371Z"
      fill="#EA4335"
    />
  </StyledIcon>
)
