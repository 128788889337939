import { LocaleConfigItem } from 'fitify-ui-landing/src/@types/ConfigTypes'
import { useRouter } from 'next/router'
import { type NextSeoProps } from 'next-seo'
import { useMemo } from 'react'

import { getCanonicalUrl } from '@/utils/seo'

import localeConfig from '../../config/localeConfig.json'

const supportedLocales: LocaleConfigItem[] = localeConfig

const languageAlternatesPages = ['/', '/contact']

/**
 * This hook returns canonical and alternative language link tags for SEO purposes.
 * For details see https://developers.google.com/search/docs/specialty/international/localized-versions?hl=en#html
 */
export const useLanguageTags = (): Pick<
  NextSeoProps,
  'canonical' | 'languageAlternates'
> => {
  const router = useRouter()

  return useMemo(() => {
    if (languageAlternatesPages.includes(router.pathname)) {
      return {
        languageAlternates: [
          ...supportedLocales.map((locale) => {
            return {
              hrefLang: locale.origin,
              href: getCanonicalUrl(router.asPath, locale.origin),
            }
          }),
          {
            hrefLang: 'x-default',
            href: getCanonicalUrl(router.asPath),
          },
        ],
      }
    } else {
      return {
        canonical: getCanonicalUrl(router.asPath),
      }
    }
  }, [router.asPath, router.pathname])
}
