import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const AppleIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.354 16.4879C20.016 15.9819 19.121 14.7669 19.02 13.3179C18.921 11.9059 19.613 10.6519 20.871 9.96289L21.917 9.38989L21.17 8.45989C19.915 6.89689 18.119 5.96289 16.366 5.96289C15.151 5.96289 14.308 6.28089 13.631 6.53689C13.153 6.71789 12.776 6.85989 12.362 6.85989C11.89 6.85989 11.424 6.69389 10.884 6.50189C10.176 6.24989 9.37401 5.96389 8.34401 5.96389C6.35401 5.96389 4.34701 7.15189 3.10701 9.06189C1.25601 11.9109 1.76401 16.7959 4.31501 20.6779C5.32601 22.2159 6.74301 23.9829 8.75001 24.0009C8.76301 24.0009 8.77601 24.0009 8.78901 24.0009C10.432 24.0009 10.792 23.1249 12.387 23.1149C14.129 23.1969 14.349 24.0079 15.976 23.9969C17.937 23.9789 19.351 22.2259 20.475 20.5129C21.139 19.5059 21.396 18.9789 21.913 17.8349L22.351 16.8649L21.354 16.4879Z"
        fill="currentColor"
      />
      <path
        d="M15.1 3.45033C15.75 2.61633 16.243 1.43933 16.064 0.236328C15.002 0.309328 13.762 0.984328 13.037 1.86433C12.379 2.66333 11.836 3.84733 12.047 4.99933C13.205 5.03533 14.404 4.34333 15.1 3.45033Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
