import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const NotionIcon = (props: IconProps) => {
  return (
    <StyledIcon
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.908 0.0182554L0.48136 0.345215C0.12424 0.376095 0 0.609455 0 0.889135V5.74194C0 5.95978 0.07736 6.14618 0.264 6.3953L1.30456 7.74833C1.47552 7.96617 1.63096 8.0129 1.95736 7.99738L7.09792 7.68617C7.53256 7.65521 7.65712 7.45282 7.65712 7.11074V1.6513C7.65712 1.4745 7.58728 1.42354 7.38168 1.27266L5.93336 0.251535C5.59152 0.00297541 5.45176 -0.0284646 4.908 0.0181754V0.0182554ZM2.0736 1.56194C1.65384 1.59018 1.55864 1.59658 1.32024 1.40274L0.71416 0.920655C0.65256 0.858255 0.68352 0.780415 0.83872 0.764895L5.09416 0.453935C5.45152 0.422735 5.6376 0.547295 5.77736 0.656095L6.5072 1.1849C6.5384 1.20066 6.616 1.2937 6.52264 1.2937L2.128 1.55826L2.0736 1.56194ZM1.58424 7.0641V2.42946C1.58424 2.22706 1.6464 2.1337 1.83248 2.11802L6.88 1.8225C7.0512 1.80706 7.12856 1.91586 7.12856 2.11794V6.72169C7.12856 6.92409 7.09736 7.0953 6.81792 7.11074L1.98776 7.39074C1.70832 7.40618 1.58432 7.31314 1.58432 7.0641H1.58424ZM6.35224 2.67794C6.3832 2.81794 6.35224 2.95794 6.21224 2.97394L5.97944 3.0201V6.44194C5.77728 6.55074 5.5912 6.6129 5.43568 6.6129C5.18712 6.6129 5.12504 6.53506 4.93888 6.30194L3.41648 3.90674V6.2241L3.89808 6.33314C3.89808 6.33314 3.89808 6.61314 3.50952 6.61314L2.43832 6.6753C2.40712 6.6129 2.43832 6.45746 2.54688 6.4265L2.82664 6.3489V3.2849L2.4384 3.25346C2.4072 3.11346 2.4848 2.9113 2.7024 2.89562L3.85176 2.81826L5.43576 5.24442V3.09802L5.032 3.05162C5.0008 2.88018 5.12504 2.75562 5.28024 2.74042L6.35224 2.67794Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
